import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import ImageSlider from './Utiilities/ImageSlider';
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import Lightbox from './Utiilities/Lightbox';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import ScrollToTop from './Utiilities/ScrolltoTop';
import G2Badge from './Utiilities/G2Badge';
import TabsWithImage from './Utiilities/TabsWithImage';
import Availability from './Utiilities/Availability';
import OurClients from './Utiilities/OurClients';
import Integration from './Utiilities/Integration';

function CLM() {
    const dynamicImages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos1_RPf_AeXggA.png?updatedAt=1700627745162',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos2_fZX_JD70Nu.png?updatedAt=1700627744112',
        // Add more image URLs as needed
    ];
    const planData = [
        {
            name: 'Standard',
            price: '$49',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',
            features: ['Minimum 5 users', 'Max 30 contracts / year', 'Unlimited support through email only', 'Integrates with Microsoft 365', 'Data stays within Microsoft 365', 'Contract Authoring', 'One level approval flow', 'Negotiation', 'Execution'],

        },
        {
            name: 'Plus',
            price: '$69',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: ['Minimum 5 users', 'Max 60 contracts / year', 'Unlimited support through email only', 'Free updates via MS store', 'Mobile responsive', 'Limited Approval Workflows', 'Draft, Negotiate & Execute', 'Contract Repository', 'Clause Library', 'Reports'],
        },
        {
            name: 'Premium',
            price: '$89',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: ['Minimum 5 users', 'Max 120 contracts / year', 'Additional 10 contracts/month at $89.99', 'Unlimited support includes live chat', 'Free updates with support', '3 Contract templates', 'Two Level Approvals', 'Alerts & Notifications', 'Version control', 'Amendment', 'Renewals', 'Advance Search Capabilities', 'Advance Reporting', 'Role based Security', 'Add Documents'],
        },
        {
            name: 'Enterprise',
            price: '$129',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: ['Minimum 5 users', 'Max 240 contracts / year', 'Additional 10 contracts/month at $129.99', 'Unlimited support with screen sharing', '7 Contract templates', 'Multilevel Approvals', 'Obligation Management', 'Import Existing Contracts', 'AI based Obligations', 'Audit Trail', 'Activity Log', 'Add Documents', 'Risk Assessment', 'Approval through Emails', 'Digital Signature', 'Dashboard', 'MS Teams & Outlook App'],
        },
    ];
    const data = [

        {
            heading: 'Assets depreciation – calculation &amp; reporting'
            ,
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/AMP_SS/Depreciation_details_QEJu2iBIX.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664186732101',
        },
        {
            heading: 'Bar code &amp; QR code',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/AMP_SS/bar_code_O1_82R9Ki.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664169542304',
        },
        {

            heading: 'Assets Report',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/AMP_SS/bar_code_O1_82R9Ki.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664169542304',
        },
        {

            heading: 'Connect Asset Management 365 with your other apps',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/powerplatformtoolset_ReRdfzh4N.png?updatedAt=1700466299384',
        },


        // Add more objects as needed
    ];
    const accordionItems = [
        {
            title: '1. What is a Contract Management System, and how does it benefit my organization?', content: `A Contract Management System is a software solution that helps organizations manage their contracts efficiently. It centralizes contract storage, automates workflows, and provides tools for tracking contract milestones, compliance, and renewals. By using a contract management system, your organization can reduce risks, improve compliance, and streamline contract processes. ` },
        {
            title: `2. How does a Contract Management App help in managing contracts on the go?`, content: `A Contract Management App allows users to access, review, and manage contracts directly from their mobile devices. This flexibility ensures that key stakeholders can approve, amend, or monitor contracts anytime, anywhere, which accelerates the contract lifecycle and enhances collaboration. ` },
        {
            title: `3. What are the advantages of using SharePoint for Contract Management Software?`,
            content: `SharePoint Contract Management Software integrates seamlessly with Microsoft SharePoint, leveraging its document management capabilities. This integration enables organizations to utilize SharePoint's robust features like version control, document sharing, and collaboration tools, making contract management more efficient and secure.`
        }, {
            title: '4. How does Microsoft Contract Management Software integrate with other Microsoft products?',
            content: `Microsoft Contract Management Software is designed to integrate smoothly with other Microsoft products, such as Office 365, Dynamics 365, and Azure. This integration allows for a unified platform where contracts can be managed alongside other business processes, enhancing productivity and ensuring data consistency across systems.`
        },
        {
            title: '5. What features should I look for in Contract Management Software?',
            content: `When choosing Contract Management Software, look for features such as automated workflows, customizable templates, compliance tracking, audit trails, and robust reporting. These features help streamline contract creation, approval, and management processes while ensuring compliance and reducing operational risks.`
        }
    ];
    const tabs = [
        { id: 1, title: 'Microsoft Ecosystem', Heading: 'Effortless Integration with Microsoft Ecosystem: Contract Management System', content: (
            <ul>
                <li>
                Maximize your existing Microsoft ecosystem by incorporating our advanced contract management system.
                </li>
                <li>
                This intuitive, cloud-based software integrates smoothly with Microsoft 365, boosting your productivity and efficiency.
                </li>
                <li>
                Utilizing the power of SharePoint and functioning as a Microsoft Teams app, it offers a centralized hub for all your contract management needs.
                </li>
                <li>
                Seamless synchronization with Microsoft Outlook for streamlined contract tracking and notifications.
                </li>
            </ul>
        ),
image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Searching_bszxfaq2js.gif?updatedAt=1720500232508'},
        { id: 2, title: 'Contract Operations', Heading: 'Streamlined Contract Operations: SharePoint Contract Management app', content: (
            <ul>
                <li>
                Microsoft contract management software simplifies the entire contract lifecycle, integrating seamlessly with DocuSign, e-sign, and your Microsoft environment.
                </li>
                <li>
                SharePoint Contract Management provides exceptional security, control, and visibility.
                </li>
                <li>
                It features smart draft-to-signature workflows, customizable scenarios, and automated alerts for timely obligations.
                </li>
                <li>
                Enhanced collaboration through real-time updates and shared access within Microsoft Teams.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Searching_bszxfaq2js.gif?updatedAt=1720500232508' },
        { id: 3, title: 'User-centered', Heading: 'User-centered Approach to Contract Management tool Design', content: (
            <ul>
                <li>
                Using Microsoft Fluent UI, our contract management application provides an intuitive interface for easy navigation and minimal learning curve.
                </li>
                <li>
                Enhanced by AI, it offers dynamic contract interactions and an adaptable workflow.
                </li>
                <li>
                AI-driven document comparison ensures precise tracking of changes, improving accuracy and efficiency in reviewing modifications.
                </li>
                <li>
                Personalized user settings allow customization of dashboards and notifications to suit individual preferences.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/visualization_viJFIn6Wt.gif?updatedAt=1720500231624' },
        {
            id: 4, title: 'Agreements', Heading: 'Microsoft Contract Management with simplified Agreements', content: (
                <ul>
                    <li>
                    Contract Management software is your comprehensive toolkit for handling critical documents such as supplier agreements, customer contracts, and employment terms.
                    </li>
                    <li>It ensures seamless creation, sharing, and organization of these contracts to streamline business operations.</li>
                    <li>Think of it like organizing a large-scale party, where you handle invitations, meals, and music coordination, but for your business agreements.</li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/dataSync__gvNUPNCn.gif?updatedAt=1720500232251'
        },
        {
            id: 5, title: 'Security', Heading: 'Enterprise-Grade Security and Compliance: Contract Management Software', content:(
                <ul>
                    <li>
                    Detailed Audit Trails
                    </li>
                    <li>
                    Precision Access Control
                    </li>
                    <li>
                    Tenant-Specific Central Repository
                    </li>
                    <li>
                    Flexible Custom Reporting
                    </li>
                    <li>
                    Proactive Alerts and AI-Driven Notifications
                    </li>
                    <li>
                    Top-Tier Security and Privacy Standards
                    </li>
                    <li>
                    Built-in Digital Signatures
                    </li>
                </ul>
            ),
             image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/dataSync__gvNUPNCn.gif?updatedAt=1720500232251'
        }
       
    ];
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState('');

    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    return (
        <>
            <ScrollToTop />
            <Header />
            <div className='HR_panel'>
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >
                    <div className='HR_Section1LeftSide'>
                        <h1 className='HR_primaryclr  ' >Contract Management Software - Contract Management 365</h1>
                        <p className='HR_textclr'>Simplify contract oversight with Contract Management 365. Empower your team with intuitive tracking, automated alerts, and comprehensive document management for effortless agreement management.</p>

                    </div>
                    <div className='HR_Section1RightSide'>
                        <img alt='MainImage' src={"https://ik.imagekit.io/zn4au2jftpm5/hr365/random-images/20944145__1_-removebg-preview%20(1)_8HExemHEKq.png?updatedAt=1708084034004"} loading="lazy" />
                    </div>
                </div>
            </div>



            <div>
                <G2Badge
                    heading="Now Contract Management 365 App is Available on Microsoft Teams"
                    imageUrl="https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/CLM%20365%20teams_yFI8NzGjap.png?updatedAt=1725450649581" />
            </div>
            <div id="features" className="IdChanges"></div>
            <div>
                <TabsWithImage tabs={tabs} />
            </div>
            <div>
                <Availability />
            </div>
            <div id="client" className="IdChanges"></div>
            <div className='HR_whitesection'>
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>

                <div className='HR_MT'>
                    <OurClients />
                </div>
            </div>  <div id="integration" className="IdChangesIntegration"></div>
            <div  className='integartionPT'>
                <Integration appName={"Contract Management 365"} />

            </div>
            <div  className="IdChangesIntegration"></div>
            <h2 className='HR_heading HR_MT' id="pricing">Contract Management 365 Plans
            </h2>

            <div className='HR_MT HR_whitesection'>

                <div>
                    <Plans plans={planData} />
                </div>
            </div>
           
            <SpecailButtons  userCount={"50+"} />
            <h4 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
            </h4>
            <div className='Hr_flex HR_MT'>
                <DemoButtons />
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ' >

                <div>
                    <h3 className='HR_faq'>Frequently Asked Questions</h3>
                    <Accordion items={accordionItems} />
                </div>


            </div>

            <Footer />
        </>
    )
}
export default CLM;